/**
 * @license
 * MyFonts Webfont Build ID 3568821, 2018-04-27T06:13:23-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Trenda-BoldIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/bold-italic/
 * 
 * Webfont: Trenda-BlackIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/black-it/
 * 
 * Webfont: Trenda-ExtraLight by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/extra-light/
 * 
 * Webfont: Trenda-Bold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/bold/
 * 
 * Webfont: Trenda-Black by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/black/
 * 
 * Webfont: Trenda-ExtraLightIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/extra-light-italic/
 * 
 * Webfont: Trenda-Heavy by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/heavy/
 * 
 * Webfont: Trenda-HeavyIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/heavy-italic/
 * 
 * Webfont: Trenda-Light by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/light/
 * 
 * Webfont: Trenda-LightIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/light-italic/
 * 
 * Webfont: Trenda-Regular by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/regular/
 * 
 * Webfont: Trenda-RegularIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/regular-italic/
 * 
 * Webfont: Trenda-Semibold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/semibold/
 * 
 * Webfont: Trenda-SemiboldIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/semibold-italic/
 * 
 * Webfont: Trenda-Thin by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/thin/
 * 
 * Webfont: Trenda-ThinIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/thin-italic/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3568821
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2016 by Daniel Hernandez &amp; Paula Nazal. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3674b5');

@font-face {
  font-family: 'Trenda-BoldIt';
  src: url('assets/fonts/3674B5_0_0.eot');
  src: url('assets/fonts/3674B5_0_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_0_0.woff2') format('woff2'), url('assets/fonts/3674B5_0_0.woff') format('woff'),
    url('assets/fonts/3674B5_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-BlackIt';
  src: url('assets/fonts/3674B5_1_0.eot');
  src: url('assets/fonts/3674B5_1_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_1_0.woff2') format('woff2'), url('assets/fonts/3674B5_1_0.woff') format('woff'),
    url('assets/fonts/3674B5_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-ExtraLight';
  src: url('assets/fonts/3674B5_2_0.eot');
  src: url('assets/fonts/3674B5_2_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_2_0.woff2') format('woff2'), url('assets/fonts/3674B5_2_0.woff') format('woff'),
    url('assets/fonts/3674B5_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Bold';
  src: url('assets/fonts/3674B5_3_0.eot');
  src: url('assets/fonts/3674B5_3_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_3_0.woff2') format('woff2'), url('assets/fonts/3674B5_3_0.woff') format('woff'),
    url('assets/fonts/3674B5_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Black';
  src: url('assets/fonts/3674B5_4_0.eot');
  src: url('assets/fonts/3674B5_4_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_4_0.woff2') format('woff2'), url('assets/fonts/3674B5_4_0.woff') format('woff'),
    url('assets/fonts/3674B5_4_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-ExtraLightIt';
  src: url('assets/fonts/3674B5_5_0.eot');
  src: url('assets/fonts/3674B5_5_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_5_0.woff2') format('woff2'), url('assets/fonts/3674B5_5_0.woff') format('woff'),
    url('assets/fonts/3674B5_5_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Heavy';
  src: url('assets/fonts/3674B5_6_0.eot');
  src: url('assets/fonts/3674B5_6_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_6_0.woff2') format('woff2'), url('assets/fonts/3674B5_6_0.woff') format('woff'),
    url('assets/fonts/3674B5_6_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-HeavyIt';
  src: url('assets/fonts/3674B5_7_0.eot');
  src: url('assets/fonts/3674B5_7_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_7_0.woff2') format('woff2'), url('assets/fonts/3674B5_7_0.woff') format('woff'),
    url('assets/fonts/3674B5_7_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Light';
  src: url('assets/fonts/3674B5_8_0.eot');
  src: url('assets/fonts/3674B5_8_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_8_0.woff2') format('woff2'), url('assets/fonts/3674B5_8_0.woff') format('woff'),
    url('assets/fonts/3674B5_8_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-LightIt';
  src: url('assets/fonts/3674B5_9_0.eot');
  src: url('assets/fonts/3674B5_9_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_9_0.woff2') format('woff2'), url('assets/fonts/3674B5_9_0.woff') format('woff'),
    url('assets/fonts/3674B5_9_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Regular';
  src: url('assets/fonts/3674B5_A_0.eot');
  src: url('assets/fonts/3674B5_A_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_A_0.woff2') format('woff2'), url('assets/fonts/3674B5_A_0.woff') format('woff'),
    url('assets/fonts/3674B5_A_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-RegularIt';
  src: url('assets/fonts/3674B5_B_0.eot');
  src: url('assets/fonts/3674B5_B_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_B_0.woff2') format('woff2'), url('assets/fonts/3674B5_B_0.woff') format('woff'),
    url('assets/fonts/3674B5_B_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Semibold';
  src: url('assets/fonts/3674B5_C_0.eot');
  src: url('assets/fonts/3674B5_C_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_C_0.woff2') format('woff2'), url('assets/fonts/3674B5_C_0.woff') format('woff'),
    url('assets/fonts/3674B5_C_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-SemiboldIt';
  src: url('assets/fonts/3674B5_D_0.eot');
  src: url('assets/fonts/3674B5_D_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_D_0.woff2') format('woff2'), url('assets/fonts/3674B5_D_0.woff') format('woff'),
    url('assets/fonts/3674B5_D_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-Thin';
  src: url('assets/fonts/3674B5_E_0.eot');
  src: url('assets/fonts/3674B5_E_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_E_0.woff2') format('woff2'), url('assets/fonts/3674B5_E_0.woff') format('woff'),
    url('assets/fonts/3674B5_E_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Trenda-ThinIt';
  src: url('assets/fonts/3674B5_F_0.eot');
  src: url('assets/fonts/3674B5_F_0.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/3674B5_F_0.woff2') format('woff2'), url('assets/fonts/3674B5_F_0.woff') format('woff'),
    url('assets/fonts/3674B5_F_0.ttf') format('truetype');
}
