@import url(//hello.myfonts.net/count/3674b5);
/**
 * @license
 * MyFonts Webfont Build ID 3568821, 2018-04-27T06:13:23-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Trenda-BoldIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/bold-italic/
 * 
 * Webfont: Trenda-BlackIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/black-it/
 * 
 * Webfont: Trenda-ExtraLight by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/extra-light/
 * 
 * Webfont: Trenda-Bold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/bold/
 * 
 * Webfont: Trenda-Black by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/black/
 * 
 * Webfont: Trenda-ExtraLightIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/extra-light-italic/
 * 
 * Webfont: Trenda-Heavy by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/heavy/
 * 
 * Webfont: Trenda-HeavyIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/heavy-italic/
 * 
 * Webfont: Trenda-Light by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/light/
 * 
 * Webfont: Trenda-LightIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/light-italic/
 * 
 * Webfont: Trenda-Regular by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/regular/
 * 
 * Webfont: Trenda-RegularIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/regular-italic/
 * 
 * Webfont: Trenda-Semibold by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/semibold/
 * 
 * Webfont: Trenda-SemiboldIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/semibold-italic/
 * 
 * Webfont: Trenda-Thin by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/thin/
 * 
 * Webfont: Trenda-ThinIt by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/trenda/thin-italic/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3568821
 * Licensed pageviews: 10,000
 * Webfonts copyright: Copyright &#x00A9; 2016 by Daniel Hernandez &amp; Paula Nazal. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: 'Trenda-BoldIt';
  src: url(/static/media/3674B5_0_0.47d2843d.eot);
  src: url(/static/media/3674B5_0_0.47d2843d.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_0_0.70761644.woff2) format('woff2'), url(/static/media/3674B5_0_0.9f0d66a8.woff) format('woff'),
    url(/static/media/3674B5_0_0.d8c15197.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-BlackIt';
  src: url(/static/media/3674B5_1_0.0591a40a.eot);
  src: url(/static/media/3674B5_1_0.0591a40a.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_1_0.c99ba64b.woff2) format('woff2'), url(/static/media/3674B5_1_0.888346e4.woff) format('woff'),
    url(/static/media/3674B5_1_0.a3160f3c.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-ExtraLight';
  src: url(/static/media/3674B5_2_0.ad0a2c02.eot);
  src: url(/static/media/3674B5_2_0.ad0a2c02.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_2_0.4db5b932.woff2) format('woff2'), url(/static/media/3674B5_2_0.13990dba.woff) format('woff'),
    url(/static/media/3674B5_2_0.66058080.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Bold';
  src: url(/static/media/3674B5_3_0.9b241642.eot);
  src: url(/static/media/3674B5_3_0.9b241642.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_3_0.3c5773de.woff2) format('woff2'), url(/static/media/3674B5_3_0.e0aaa9ef.woff) format('woff'),
    url(/static/media/3674B5_3_0.a9657166.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Black';
  src: url(/static/media/3674B5_4_0.ed0177c2.eot);
  src: url(/static/media/3674B5_4_0.ed0177c2.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_4_0.4b995359.woff2) format('woff2'), url(/static/media/3674B5_4_0.8e2476d4.woff) format('woff'),
    url(/static/media/3674B5_4_0.e2ad30ce.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-ExtraLightIt';
  src: url(/static/media/3674B5_5_0.13780553.eot);
  src: url(/static/media/3674B5_5_0.13780553.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_5_0.149dfe54.woff2) format('woff2'), url(/static/media/3674B5_5_0.7262282b.woff) format('woff'),
    url(/static/media/3674B5_5_0.b0865109.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Heavy';
  src: url(/static/media/3674B5_6_0.4046e1b8.eot);
  src: url(/static/media/3674B5_6_0.4046e1b8.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_6_0.1f4989d8.woff2) format('woff2'), url(/static/media/3674B5_6_0.9cdc944d.woff) format('woff'),
    url(/static/media/3674B5_6_0.4c1c4cbe.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-HeavyIt';
  src: url(/static/media/3674B5_7_0.af13c556.eot);
  src: url(/static/media/3674B5_7_0.af13c556.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_7_0.47042e42.woff2) format('woff2'), url(/static/media/3674B5_7_0.dc1beb45.woff) format('woff'),
    url(/static/media/3674B5_7_0.a32c16f8.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Light';
  src: url(/static/media/3674B5_8_0.7f439015.eot);
  src: url(/static/media/3674B5_8_0.7f439015.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_8_0.b5976bf7.woff2) format('woff2'), url(/static/media/3674B5_8_0.eb1c1043.woff) format('woff'),
    url(/static/media/3674B5_8_0.7701acc2.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-LightIt';
  src: url(/static/media/3674B5_9_0.cb646254.eot);
  src: url(/static/media/3674B5_9_0.cb646254.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_9_0.f9f43d84.woff2) format('woff2'), url(/static/media/3674B5_9_0.7c387770.woff) format('woff'),
    url(/static/media/3674B5_9_0.97727a98.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Regular';
  src: url(/static/media/3674B5_A_0.d8b52d09.eot);
  src: url(/static/media/3674B5_A_0.d8b52d09.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_A_0.f827725d.woff2) format('woff2'), url(/static/media/3674B5_A_0.db2f83b3.woff) format('woff'),
    url(/static/media/3674B5_A_0.bf26506f.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-RegularIt';
  src: url(/static/media/3674B5_B_0.99bbc841.eot);
  src: url(/static/media/3674B5_B_0.99bbc841.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_B_0.950b8b6f.woff2) format('woff2'), url(/static/media/3674B5_B_0.a1093777.woff) format('woff'),
    url(/static/media/3674B5_B_0.f67c9ff9.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Semibold';
  src: url(/static/media/3674B5_C_0.2adc21b2.eot);
  src: url(/static/media/3674B5_C_0.2adc21b2.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_C_0.63d03a97.woff2) format('woff2'), url(/static/media/3674B5_C_0.54630864.woff) format('woff'),
    url(/static/media/3674B5_C_0.3d1631a9.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-SemiboldIt';
  src: url(/static/media/3674B5_D_0.342f8d10.eot);
  src: url(/static/media/3674B5_D_0.342f8d10.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_D_0.1c9d2891.woff2) format('woff2'), url(/static/media/3674B5_D_0.64627c8c.woff) format('woff'),
    url(/static/media/3674B5_D_0.92a31f78.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-Thin';
  src: url(/static/media/3674B5_E_0.624ee6fa.eot);
  src: url(/static/media/3674B5_E_0.624ee6fa.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_E_0.b6c1d873.woff2) format('woff2'), url(/static/media/3674B5_E_0.8a332f8b.woff) format('woff'),
    url(/static/media/3674B5_E_0.b41d0063.ttf) format('truetype');
}

@font-face {
  font-family: 'Trenda-ThinIt';
  src: url(/static/media/3674B5_F_0.7068bdd3.eot);
  src: url(/static/media/3674B5_F_0.7068bdd3.eot?#iefix) format('embedded-opentype'),
    url(/static/media/3674B5_F_0.90a308b8.woff2) format('woff2'), url(/static/media/3674B5_F_0.c469731e.woff) format('woff'),
    url(/static/media/3674B5_F_0.9d82307a.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  background-color: #262626;
}

